import type { BadgeType, BadgeSize } from '../types';
import { computed } from 'vue';
import type { Ref } from 'vue';
import classNames from 'classnames';

const defaultBadgeClasses =
  'px-2.5 py-1 rounded flex items-center justify-center';
const badgeLinkClasses =
  'bg-blue-100 hover:bg-blue-200 text-blue-800 dark:text-blue-800 dark:hover:bg-blue-300';
const onlyIconClasses = 'p-1 rounded-full';

const badgeTextClasses: Record<BadgeType, string> = {
  default: 'text-primary-800 dark:text-primary-800',
  dark: 'text-gray-800 dark:bg-gray-700',
  red: 'text-red-800 dark:text-red-900',
  green: 'text-green-800 dark:text-green-900',
  yellow: 'text-yellow-800 dark:text-yellow-900',
  indigo: 'text-indigo-800 dark:text-indigo-900',
  purple: 'text-purple-800 dark:text-purple-900',
  pink: 'text-pink-800 dark:text-pink-900',
  blue: 'text-sky-blue-1000 dark:text-sky-blue-1000',
  gray: 'text-white dark:text-white',
  'sky-blue': 'text-sky-blue-900',
  brown: 'text-orange-800',
};

const badgeTypeClasses: Record<BadgeType, string> = {
  default: 'bg-primary-100 dark:bg-primary-200',
  dark: 'bg-gray-100 dark:bg-gray-700',
  red: 'bg-red-100 dark:bg-red-200',
  green: 'bg-green-100 dark:bg-green-200',
  yellow: 'bg-yellow-100 dark:bg-yellow-200',
  indigo: 'bg-indigo-100 dark:bg-indigo-200',
  purple: 'bg-purple-100 dark:bg-purple-200',
  pink: 'bg-pink-100 dark:bg-pink-200',
  blue: 'bg-primary-200 dark:bg-primary-200',
  gray: 'bg-secondary-800 dark:bg-secondary-800',
  'sky-blue': 'bg-sky-blue-200',
  brown: 'bg-orange-300',
};

const badgeSizeClasses: Record<BadgeSize, string> = {
  xs: 'text-xs font-semibold',
  sm: 'text-sm font-medium',
};

export type UseBadgeClassesProps = {
  type: Ref<BadgeType>;
  size: Ref<BadgeSize>;
  square: Ref<boolean>;
  pill: Ref<boolean>;
  href: Ref<string>;
};
export type UseBadgeClassesOptions = {
  isContentEmpty: Ref<boolean>;
};

export function useBadgeClasses(
  props: UseBadgeClassesProps,
  options: UseBadgeClassesOptions,
): {
  badgeClasses: Ref<string>;
} {
  const badgeClasses = computed<string>(() => {
    return classNames(
      badgeSizeClasses[props.size.value],
      props.href.value ? '' : badgeTypeClasses[props.type.value],
      props.href.value ? '' : badgeTextClasses[props.type.value],
      props.href.value ? badgeLinkClasses : '',
      props.square.value ? 'rounded-none' : '',
      props.pill.value ? 'rounded-full' : '',
      options.isContentEmpty.value ? onlyIconClasses : defaultBadgeClasses,
    );
  });
  return {
    badgeClasses,
  };
}
